.live-monitoring-container {
  position: relative;
}

.wrap-card-form-search-truck {
  margin-bottom: 16px;
}

.wrap-form-search-truck,
.wrap-form-search-store,
.wrap-form-search-geofence {
  form {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .ant-form-item {
      margin-right: 0px;
    }

    .form-button-section {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding-left: 50px;
    }
  }

  input[type="date"] {
    height: 32px;
  }

  .submit-button {
    color: white;
    background-color: #006d2e;

    &:hover {
      color: white !important;
      background-color: #409261 !important;
    }
  }
}

.action-gps-tracking {
  color: #000000;
  text-decoration: underline;
  &:hover {
    color: #000;
    text-decoration: underline;
  }
}

.truck-driver-do-type {
  text-transform: capitalize;
}

.wrap-table-truck-list {
  .phone-number {
    color: rgba(0, 0, 0, 0.85);
  }

  td span.status-badge {
    display: inline-block;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    &.complete {
      background-color: #4fc626;
    }
    &.incomplete {
      background-color: #c62626;
    }
  }
}



.custom-tag-dotype {
  .ant-select-selection-overflow {
    span {
      text-transform: capitalize;
    }
  }
}

.popup-select-dotype {
  .ant-select-item-option-content {
    text-transform: capitalize;
  }
}

.warp-header-truck {
  display: flex;
  justify-content: space-between;

  p {
    font-family: "IBM Plex Sans Thai Semibold";
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 16px;
  }
}

.accordian-dashboard-collapse {
  margin-bottom: 13px;

  .ant-collapse-expand-icon {
    color: #acacac;
  }
}

.wrap-dashboard {
  text-align: center;
}

.wrap-popover-content {
  display: flex;
  height: auto;
  padding: 14px;

  .popover-second-column {
    width: 120px;
    padding-left: 14px;
  }

  .popover-first-column {
    width: 120px;
    padding-right: 14px;
  }

  p {
    font-size: 14px;
  }
}

.popover-detail-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  p.popover-detail-section-title,
  p.popover-detail-section-content {
    font-size: 14px;
    margin: 0px;
  }

  p.popover-detail-section-content {
    color: #4765f2;
  }
}

#active-marker {
  background-image: url("../../assets/images/map/active_marker.svg");
  background-size: contain;
  width: 40px;
  height: 40px;
  &.active {
    background-image: url("../../assets/images/map/focus_active_marker.svg");
  }
}

#inactive-marker {
  background-image: url("../../assets/images/map/inactive_marker.svg");
  background-size: contain;
  width: 40px;
  height: 40px;
  &.active {
    background-image: url("../../assets/images/map/focus_inactive_marker.svg");
  }
}

.popover-status-badge {
  display: inline-block;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  margin-right: 5px;
  &.complete {
    background-color: #4fc626;
  }
  &.incomplete {
    background-color: #c62626;
  }
}

.marker-popup {
  position: absolute;
  min-width: 495px;
  top: 0;
  right: 0;
  background-color: white;
  padding: 12px;
  margin: 12px;
  border-radius: 8px;

  p {
    font-size: 14px;
    font-style: normal;
  }

  .marker-popup-select-load {
    p {
      margin-bottom: 0px;
    }
  }
}

.marker-popup-info-card {
  width: 100%;
  margin-top: 10px;
  padding: 16px;
  border-radius: 6px;
  border: 1px solid #dcdcdc;

  .info-sync-status {
    display: flex;
    align-content: center;
    align-items: center;
  }

  p {
    font-size: 14px;
    font-style: normal;
    margin-bottom: 0px;
  }
}

.marker-popup-detail-section {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f7f7f7;
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 16px;

  p.detail-title {
    font-size: 14px;
    font-style: normal;
    font-family: IBM Plex Sans Thai Medium;
    margin-bottom: 4px;
  }

  .marker-popup-detail-section-info {
    padding: 0px 12px;

    .detail-section-info {
      display: flex;

      p {
        font-size: 14px;
        font-style: normal;
        margin-bottom: 0px;
      }
    }
  }
}

.loading-progress {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 98px;
}

.map-remark-message {
  margin: 12px 0;
  background-color: #f4f5f8;
  border: 1px solid #d9deeb;
  .ant-alert-message {
    color: #201c00;
  }
  .anticon-info-circle {
    color: #929cb3;
  }
}

.progress-status {
  color: #acacac;
}

.cannot-connect-totommap {
  height: 559px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .error-occurred {
    color: #667080;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin-top: 10px;
  }
  .please-try-again {
    color: #a3a9b3;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .btn-reconnect {
    margin-top: 10px;
  }
}

.ant-btn{
  transition: none;
}


b{
  font:16px IBM Plex Sans Thai Medium
}

.normal-header{
  font: 16px IBM Plex Sans Thai Regular
}