@import "design-system/src/styles/typography.scss";
@import "design-system/src/styles/_variables.scss";

p {
  font: $WebBody1;
}

.upload-file {
  border-radius: 8px;
  background-color: $core-palette-gray-5;
  padding: 24px;

  .text-caption {
    font: $WebCaption;
    color: $content-description;
    padding-bottom: 16px;
  }

  .dragger-file-box {
    padding: 24px 0;
    margin-bottom: 0;

    p {
      margin-bottom: 0;
    }

    .dragger-icon {
      font-size: 40px;
      color: $content-primary-subdued;
    }

    .dragger-description {
      font: $WebCaption;
      color: $content-description;
      padding-top: 8px;
    }
  }

  .file-uploaded {
    display: flex;
    align-items: center;

    p {
      margin-bottom: 0;
    }

    .delete-icon {
      color: $content-description;
    }
  }
}

.submit-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 13px;

  .template-file-button {
    padding: 8px 16px;
    color: $content-primary-default;
  }
}

.notification {
  h4 {
    font: $WebH4;
  }

  p {
    font: $WebBody1;
    margin-bottom: 0;
  }

  border-radius: 8px !important;
  padding: 12px !important;

  &.success {
    border: 1px solid $core-palette-success-30;
    background: $core-palette-success-10;
  }

  &.error {
    border: 1px solid $core-palette-danger-30;
    background: $core-palette-danger-10;
    .icon {
      color: $content-danger-default;
    }
  }

  &.icon {
    height: max-content !important;
    padding: 0 8px 0 0 !important;
    color: $content-success-default;
  }
}

.collapse-component {
  .ant-collapse-header {
    padding: 16px 24px !important;
  }

  .ant-collapse-content-box {
    padding: 0 24px !important;
  }

  .collapse-icon {
    font-size: 22px !important;
    color: $content-subdued !important;
  }
}

.alert-mesage {
  .ant-alert-message {
    margin-bottom: 0;
  }
}

.status-tag {
  font-size: 12px;
  padding: 0px 8px;
  border: none;

  &.success {
    background-color: $surface-static-success-default;
    color: $content-success-default;
  }

  &.error {
    background-color: $surface-static-danger-default;
    color: $content-danger-default;
  }

  &.warning {
    background-color: $surface-static-warning-default;
    color: $content-warning-default;
  }

  &.info {
    background-color: $surface-static-process-default;
    color: $content-process-default;
  }

  &.default {
    background-color: $surface-static-ui-active;
    color: $content-description;
  }
}

.baomove-loading-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  backdrop-filter: blur(1px);
}

.loading-animate {
  width: 606px;
  height: auto;
}
